/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* code {
  font-stretch: narrower;
} */

body {
	margin: 0px;
	padding: 0px;
	cursor: none;
  overscroll-behavior: none;
	/* overflow-x: hidden; */
	@media only screen and (max-width: 765px) {
		cursor: auto;
	}
}
